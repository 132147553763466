import {useSelector} from "react-redux";
import {selectEditProfile, setEditProfile} from "../store/editProfile/editProfileSlice";
import {useAppDispatch} from "./redux";
import {useEffect} from "react";
import {useModal} from "./useModal";
import useBottomModal from "./useBottomModal";

const useEditProfile = () => {
    const editProfile = useSelector(selectEditProfile)
    const dispatch = useAppDispatch()

    // hook cleanup: when user opens edit profile modal should close and form should reset
    const {closeModal} = useModal()
    const {resetBottomModalForm} = useBottomModal()
    useEffect(() => {
        if(editProfile){
            closeModal()
            // resetBottomModalForm()
            // emit(new CustomEvent(config.customEvents.REFRESH_REVIEW))
        }
    }, [editProfile])

    const openProfile = () => {
        dispatch(setEditProfile(true))
    }

    const closeProfile = () => {
        dispatch(setEditProfile(false))
    }


    return {
        editProfile,
        openProfile,
        closeProfile
    }
};

export default useEditProfile;
