import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../reducers";


declare interface EditProfileProps {
    editProfile: boolean
}

const initialState: EditProfileProps = {
    editProfile: false
}

export const editProfileSlice = createSlice({
    name: 'editProfile',
    initialState,
    reducers: {
        setEditProfile: (state, action: PayloadAction<any>) => {
            state.editProfile = action.payload
        },
    },
})

export const {setEditProfile} = editProfileSlice.actions

export const selectEditProfile = (state: RootState) => state.editProfile.editProfile

export default editProfileSlice.reducer
