import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "../../interfaces/profile";
import { Board } from "../../interfaces/board";
import {RootState} from "../reducers";

interface ProfileState {
  profile?: Profile;
  availableBoards?: Board[];
  clinicalRoles: string[];
  profileLoaded: boolean;
}

const initialState: ProfileState = {
  profileLoaded: false,
  profile: {
    birthDate: undefined,
    boards: [],
    email: undefined,
    graduationDate: undefined,
    id: undefined,
    lastName: undefined,
    name: undefined,
    role: undefined,
    clinicalRole: undefined,
    xid: undefined,
  },
  availableBoards: [],
  clinicalRoles: [],
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<any>) => {
      state.profile = action.payload.profile;
      state.availableBoards = action.payload.availableBoards;
      state.clinicalRoles = action.payload.clinicalRoles
      state.profileLoaded = true;
    },
    setProfileLoaded: (state, action: PayloadAction<any>) => {
      state.profileLoaded = action.payload;
    },
  },
});

export const { setProfile, setProfileLoaded } = profileSlice.actions;

export const selectUser = (state: RootState) => state.profile.profile;
export const selectAvailableBoards = (state: RootState) => state.profile.availableBoards;
export const selectClinicalRoles = (state: RootState) => state.profile.clinicalRoles

export default profileSlice.reducer;
