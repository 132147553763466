import React from 'react';
import {Link, Stack, Text} from "@amboss/design-system";

const SocketErrorCard = () => {
    return (
        <div style={{
            display: 'flex',
            maxWidth: 328,
            margin: "auto",
            marginTop: (window.innerHeight / 2) - 92,
        }}>
            <Stack>
                <Text weight={"bold"} align={"center"}>Your session has expired</Text>
                <Text size={"s"} align="center" color={"tertiary"}>
                    To continue using our CME service, please <Link color={"secondary"}
                                                                    href={"https://www.amboss.com/us/account/cme"}>renew
                    your access</Link> or go back to the
                    AMBOSS <Link href={"https://next.amboss.com/us/"}
                                 color={"secondary"}>dashboard</Link> and access the service
                    again.
                </Text>
            </Stack>
        </div>
    );
};

export default SocketErrorCard;
