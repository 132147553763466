import React from 'react';
import {Icon, Inline, Text} from "@amboss/design-system";

declare type HeadingProps = {
    text?: string,
    children?: JSX.Element,
}

const Heading: React.FC<HeadingProps> = ({text, children}) => {
    return (
        <Inline space={"l"}>
            <Icon
                name="user"
                size="m"
                color="primary"
            />
            <div style={{maxWidth: 800}}>
                <Text>
                    {text || children}
                </Text>
            </div>
        </Inline>
    );
};

export default Heading;
