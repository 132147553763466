declare global {
    interface Window {
        ambossAnalytics: any;
    }
}

declare type EventProps = {
    event: string,
    options: object
}

declare type IdentifyProps = {
    xid: string,
    traits: object
}

export const trackEvent = ({event, options}: EventProps) => {
    window.ambossAnalytics?.track(event, options);
}

export const identify = ({xid, traits}: IdentifyProps) => {
    window.ambossAnalytics?.identify(xid, traits);
}
