import {useAppDispatch, useAppSelector} from "./redux";
import {selectLoading, setValue} from "../store/loading/loadingSlice";

const useLoading = () => {
    const loading = useAppSelector(selectLoading);
    const dispatch = useAppDispatch();

    const setLoading = (value: boolean) => {
        dispatch(setValue(value))
    }

    return {
        loading,
        setLoading
    }
};

export default useLoading;
