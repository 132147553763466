import React from 'react';
import {useTranslation} from "react-i18next";
import {Credit} from "../../../../interfaces/credit";
import TabList from "../tabs/TabList";
import ItemDate from "../../../atoms/ItemDate";
import {Badge, Button, Inline} from "@amboss/design-system";
import {Credits, CreditsType} from "../../../../interfaces/credits";
import {useSearchParams} from "react-router-dom";
import {download} from "../../../../utils";
import {config} from '../../../../config';
import {trackEvent} from "../../../../analytics";
import useProfile from "../../../../hooks/useProfile";
import {useAppSelector} from "../../../../hooks/redux";
import { creditsTypeSelector} from "../../../../store/credits/creditsSlice";
import moment from "moment/moment";
import IpcItemArticle from "../../../atoms/IpcItemArticle";
import ItemNejm from "../../../atoms/ItemNejm";


declare type ClaimedListProps = {
    credits: Credits
}
const ClaimedList = ({credits}: ClaimedListProps) => {
    const {t} = useTranslation()
    const {profile} = useProfile()
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get("token")
    const creditType = useAppSelector(creditsTypeSelector);
    const handleClick = (item: Credit) => {
        const errorString = t('credits.tabs.tab3.download.error')
        trackEvent({
            event: config.analytics.events.CME_PDF_DOWNLOADED,
            options: {xid: profile?.xid, cme_activity: config.analytics.activities[creditType]}
        })
        download({
            api: config.api.endpoints.download(item.id),
            fileName: config.api.fileName(item.id),
            accessToken: accessToken || undefined,
            errorString
        })
    }

    const formattedDate = (item: any) =>{
        return creditType === CreditsType.IPC ? moment(item.updatedAt).format("MMMM D, YYYY") : `${moment(item.startDate).format("MMMM D, YYYY")} - ${moment(item.endDate).format("MMMM D, YYYY")}`
    }

    const getItemElement = (item:any) => {
        return creditType === CreditsType.IPC ? <IpcItemArticle searchTerm={item.searchTerm} articleTitles={item.articleTitles} parsStatus={item.parsStatus} parsResponse={item.parsResponse}/> : <ItemNejm itemValue={item.questionIds.length} parsStatus={item.parsStatus} parsResponse={item.parsResponse}/>
    }

    return (
        <>
            {credits?.items.map((item, i) => {
                return (
                    <TabList
                        key={item.id}
                        left={<ItemDate date={formattedDate(item)}/>}
                        center={getItemElement(item)}
                        right={<Inline alignItems={"right"} vAlignItems={"center"}>
                             <Badge color={"brand"} text={t('credits.tabs.tab2.listItem.badge', {count: item.value})}/>
                            <Button variant={'secondary'} leftIcon={"download"}
                                    onClick={() => handleClick(item)}>{t('credits.tabs.tab3.listItem.button', {count: item.value})}</Button>
                        </Inline>}
                        isLastItem={(credits.items.length - 1) === i}
                    />
                )
            })}
        </>
    );
};

export default ClaimedList;
