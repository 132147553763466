import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../reducers";

const initialState: { loading: boolean } = {
    loading: false
}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setValue: (state, action: PayloadAction<any>) => {
            return action.payload
        },
    },
})

export const {setValue} = loadingSlice.actions

export const selectLoading = (state: RootState) => state.loading

export default loadingSlice.reducer
