import React, {useContext, useState} from 'react';

declare type ModalContextType = {
    closeModal: () => void
    openModal: (data: any) => void,
    modal: Modal,
    overrideSubmit?: (cb: any) => void
}

declare type Modal = {
    open: boolean | false
    data?: any,
}

export const ModalContext = React.createContext<ModalContextType | null>(null);

export const useModal = () => useContext(ModalContext) as ModalContextType;

export const ModalProvider: React.FC<React.PropsWithChildren> = ({children}) => {
    const [modal, setModal] = useState<Modal>({
        open: false,
        data: null,
    });

    const overrideSubmit = (cb: () => void) => {
        cb()
    }

    const openModal = (data: any) => {
        setModal({open: true, data: data})
    }

    const closeModal = () => {
        setModal({open: false, data: null})
    }

    return (
        <ModalContext.Provider value={{modal, closeModal, openModal, overrideSubmit}}>
            {children}
        </ModalContext.Provider>
    )
};

export default ModalProvider;


