import React from 'react';
import {FormFieldGroup, H4, Radio, Stack, Text} from "@amboss/design-system";
import {useTranslation} from "react-i18next";

const CmeCpdCe = () => {
    const {t} = useTranslation()

    const radioLabel = t('accountSetup.sections.cmeCpdCe.label')
    const optionLabel = t('accountSetup.sections.cmeCpdCe.optionLabel')
    const hintLabel = t('accountSetup.sections.cmeCpdCe.hint')

    return (
        <Stack space={'l'}>
            <H4>{t('accountSetup.sections.cmeCpdCe.title')}</H4>
            <FormFieldGroup
                label={radioLabel}>
                <Radio label={optionLabel}
                       name="radio"
                       checked={true}
                       onChange={function noRefCheck() {
                       }}
                />
                {/*
                    negative margin and pLeft are used to obtain the visual effect since the radio button doesn't have a subLabel
                    https://www.figma.com/file/2HBeJ060ltblrCtsrrbq7t/Amboss-CME?node-id=74%3A1272&t=SVpTjnNUeZo8UkYK-4
                */}
                <div style={{marginTop: -20, paddingLeft: 40}}>
                    <Text  color={'tertiary'}>{hintLabel}</Text>
                </div>
            </FormFieldGroup>
        </Stack>
    );
};

export default CmeCpdCe;
