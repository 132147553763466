import React, {useEffect} from 'react';
import {CardBox, Tabs} from "@amboss/design-system";
import {useTranslation} from "react-i18next";
import AvailableTab from "./AvailableTab";
import ClaimedTab from "./ClaimedTab";
import ReviewedTab from "./ReviewedTab";
import useBottomModal from "../../../../hooks/useBottomModal";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {setActiveTab, tabSelector} from "../../../../store/credits/creditsSlice";

const CreditTabsIpc = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector(tabSelector);
    const {showBottomModal} = useBottomModal();

    const tabs = [
        {label: t('credits.tabs.tab1.title')},
        {label: t('credits.tabs.tab2.title')},
        {label: t('credits.tabs.tab3.title')}
    ];

    useEffect(() => {
        showBottomModal(activeTab === 1);
    }, [activeTab]);

    return (
        <Tabs
            horizontalPadding={"m"}
            activeTab={activeTab}
            onTabSelect={(selectedTab) => {
                //resetCreditStatus()
                dispatch(setActiveTab(selectedTab));
            }}
            tabs={tabs}
        >
            <CardBox>
                {activeTab === 0 && <AvailableTab/>}
                {activeTab === 1 && <ReviewedTab/>}
                {activeTab === 2 && <ClaimedTab/>}
            </CardBox>
        </Tabs>
    )

};

export default CreditTabsIpc;
