import {Box, Stack, Text} from '@amboss/design-system';
import React from 'react';

declare type EmptyPlaceholderProps = {
    header: string
    subHeader: string
}

const EmptyPlaceholder = ({header, subHeader}: EmptyPlaceholderProps) => {
    return (
        <Box>
            <Stack space={"m"}>
                <Text align='center' weight='bold' >{header}</Text>
                <Text align='center' color={"tertiary"}>{subHeader}</Text>
            </Stack>
        </Box>
    );
};

export default EmptyPlaceholder;
