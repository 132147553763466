import React from "react";
import { H4, Inline, Stack, Text } from "@amboss/design-system";
import { useTranslation } from "react-i18next";
import SpecialtyStateForm from "../specialtyState/SpecialtyStateForm";

const SpecialtyState = () => {
  const { t } = useTranslation();

  const userCodeLabelHint = t("accountSetup.sections.specialtyState.hint");
  const userCodeLabelInstructions = t("accountSetup.sections.specialtyState.instructions");

  return (
    <Stack space={"xs"}>
      <Inline vAlignItems={"bottom"}>
        <H4>{t("accountSetup.sections.specialtyState.title")}</H4>
        <Text size={"xs"} color={"tertiary"}>
          {userCodeLabelHint}
        </Text>
      </Inline>
      <div style={{marginBottom: 20}}>
        <Text size={"s"} color={"tertiary"}>
          {userCodeLabelInstructions}
        </Text>
      </div>
      <Stack space={"l"}>
        <SpecialtyStateForm stateType="speciality"/>
        <SpecialtyStateForm stateType="state"/>
      </Stack>
    </Stack>
  );
};

export default SpecialtyState;
