import React, {useEffect} from 'react';
import {CardBox, Tabs} from "@amboss/design-system";
import {useTranslation} from "react-i18next";
import ClaimedTab from "./ClaimedTab";
import ReviewedTab from "./ReviewedTab";
import useBottomModal from "../../../../hooks/useBottomModal";
import {useAppDispatch, useAppSelector} from '../../../../hooks/redux';
import { setActiveTab, tabSelector} from '../../../../store/credits/creditsSlice';
import {useSelector} from "react-redux";

const CreditTabs = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector(tabSelector);
    const {showBottomModal} = useBottomModal();

    const tabs = [
        {label: t('credits.tabs.tab2.title')},
        {label: t('credits.tabs.tab3.title')}
    ];

    useEffect(() => {
        showBottomModal(activeTab === 0);
    }, [activeTab]);

    return (
        <Tabs
            horizontalPadding={"m"}
            activeTab={activeTab}
            onTabSelect={(selectedTab) => {
                //resetCreditStatus()
                dispatch(setActiveTab(selectedTab));
            }}
            tabs={tabs}
        >
            <CardBox>
                {activeTab === 0 && <ReviewedTab/>}
                {activeTab === 1 && <ClaimedTab/>}
            </CardBox>
        </Tabs>
    )

};

export default CreditTabs;
