import {useEffect} from "react";
import {Box, Container, H5, Stack, Text} from "@amboss/design-system";
import {useTranslation} from "react-i18next";
import CreditTabs from "./tabs/CreditTabs";
import useBottomModal from "../../../hooks/useBottomModal";
import Loader from "../../atoms/Loader";
import useLoading from "../../../hooks/useLoading";
import {useAppSelector} from "../../../hooks/redux";
import {selectUser} from "../../../store/profile/profileSlice";
import {creditsTypeSelector} from "../../../store/credits/creditsSlice";
import {totalCreditsSelector} from "../../../store/totalCredits/totalCreditsSlice";
import {CreditsType} from "../../../interfaces/credits";
import CreditTabsIpc from "./tabs/CreditTabsIpc";
import Modal from "../../modals/Modal";
import BottomModal from "../../modals/BottomModal";

export const Credits = () => {
    const {t} = useTranslation();
    const {show} = useBottomModal();
    const {loading, setLoading} = useLoading();
    const user = useAppSelector(selectUser);
    const creditType = useAppSelector(creditsTypeSelector);
    const totalCredits = useAppSelector(totalCreditsSelector);
    const claimed = totalCredits[creditType].claimed;
    const tabs = creditType === CreditsType.IPC ? <CreditTabsIpc/> : <CreditTabs/>
    const claimLabel = claimed === 1 ? t(`home.labels.creditClaimed`) : t(`home.labels.creditsClaimed`);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <div style={{marginBottom: show ? 150 : 0}}>
            <Modal/>
            <Container elevation={0}>
                {loading && <Loader/>}

                {user?.clinicalRole && (
                    <>
                        <Box>
                            <Stack space={"xs"}>
                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    <H5>{t(`home.cards.${creditType}.title`)}</H5>
                                    <Text color={"tertiary"}>
                                        <b style={{color: "black"}}>{claimed}</b> {claimLabel}
                                    </Text>
                                </div>
                            </Stack>
                        </Box>
                        {tabs}
                    </>
                )}
            </Container>
            <div id="callout-portal" style={{marginTop: 30}}></div>

            {show && <BottomModal/>}
        </div>
    );
};
