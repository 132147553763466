import {useEffect, useState} from 'react';
import {Button, Column, Columns, Inline, Input, Select, Stack, Text} from "@amboss/design-system";
import {useAppSelector} from "../../../../hooks/redux";
import {selectAvailableBoards, selectUser} from "../../../../store/profile/profileSlice";
import {SelectOption} from "../../../../interfaces/availableBoardSelect";
import {useTranslation} from "react-i18next";
import {validateBoardAdd} from "../../../../validators/validators";
import {getTagFromBoard, removeItemFromArrayById} from "../../../../utils";
import Tag from "../../../atoms/Tag";
import useSelectBoard from "../../../../hooks/useSelectBoard";
import useAvailableBoardsForm from "../../../../hooks/useAvailableBoardsForm";
import {useFormikContext} from "formik";
import {config} from "../../../../config"; 
import {useMediaQuery} from "react-responsive";

const SpecialtyStateForm = ({stateType}:any) => {
    const {t} = useTranslation()
    const availableBoardsRedux = useAppSelector(selectAvailableBoards)
    const profile = useAppSelector(selectUser)
    const [options, setOptions] = useState<SelectOption[]>([])
    const [selectedBoards, setSelectedBoards] = useState<any[]>([])
    const {selectedBoard, resetBoardFields, selectBoardId, selectUserCode} = useSelectBoard()
    const {availableBoards, syncAvailableBoards} = useAvailableBoardsForm()

    const selectLabel = stateType === 'speciality' 
    ? t('accountSetup.sections.specialtyState.select.specialityLabel') 
    : t('accountSetup.sections.specialtyState.select.stateLabel')
    const selectPlaceholder = t('accountSetup.sections.specialtyState.select.placeholder')
    const userCodeLabel = stateType === 'speciality' 
    ? t('accountSetup.sections.specialtyState.input.specialityLabel') 
    : t('accountSetup.sections.specialtyState.input.stateLabel')

    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
    })

    const {setFieldValue} = useFormikContext()

    useEffect(() => {
        if (profile?.boards) {
            setSelectedBoards(profile.boards)
        }
    }, [profile])

    useEffect(() => {
        if (selectedBoard?.id && selectedBoard?.userCode) {
            setFieldValue(config.forms.specialtyState.CURRENT_BOARD, {
                boardId: selectedBoard.id,
                userCode: selectedBoard.userCode
            })
        } else {
            setFieldValue(config.forms.specialtyState.CURRENT_BOARD, null)
        }
    }, [selectedBoard])

    useEffect(() => {
        if (selectedBoards) {
            setFieldValue(config.forms.specialtyState.BOARDS, selectedBoards.map(selectedBoard => ({
                boardId: selectedBoard.id,
                userCode: selectedBoard.userCode
            })))
            syncAvailableBoards(selectedBoards)
        }
    }, [selectedBoards])

    useEffect(() => {
        if (availableBoards) {
            if (availableBoards.length > 0 && stateType === 'speciality' ) {
                const boards = availableBoards.map(board => ({value: board.id, label: board.label})).filter((val) => val.label.includes("American"))
                setOptions(boards)
            }
            if (availableBoards.length  > 0 && stateType === 'state') {
                const boards = availableBoards.map(board => ({value: board.id, label: board.label})).filter((val) => !val.label.includes("American"))
                setOptions(boards)
            }
        }
    }, [availableBoards, selectedBoards])

    const handleAdd = () => {
        setSelectedBoards(boards => ([...boards, selectedBoard]))
        resetBoardFields()
    }

    const handleTagClose = (id: string) => {
        const arr = removeItemFromArrayById(selectedBoards, id)
        syncAvailableBoards(selectedBoards)
        setSelectedBoards(arr)
    }

    return (
        <Columns gap={'m'}>
            <Column size={[12, 4, 4]}>
                <Select
                    name={config.forms.specialtyState.BOARD_ID}
                    label={selectLabel}
                    maxHeight={200}
                    autoComplete={"true"}
                    onBlur={function noRefCheck() {
                    }}
                    onChange={function (e) {
                        selectBoardId(e.currentTarget.value)
                    }}
                    onFocus={function noRefCheck() {
                    }}
                    options={options}
                    placeholder={selectPlaceholder}
                    value={selectedBoard.id}
                    />
            </Column>
            <Column size={[12, 6, 4]}>
                <Input
                    label={userCodeLabel}
                    name={config.forms.specialtyState.USER_CODE}
                    placeholder={t('accountSetup.sections.personalInformation.form.placeholders.text').toString()}
                    type={'text'}
                    value={selectedBoard.userCode || ''}
                    onChange={e => {
                        selectUserCode(e.currentTarget.value)
                    }}
                />
            </Column>
            <Column size={[12, 2, 4]}>
                <Stack>
                    {!isMobile && <div style={{height: 16}}/>}
                    <Button variant={'secondary'} leftIcon={'plus'} onClick={handleAdd}
                            disabled={validateBoardAdd(selectedBoard)}>add</Button>
                </Stack>
            </Column>
            <Inline >
                {selectedBoards.map((selectedBoard, i) => {
                    const tagText = getTagFromBoard(selectedBoard, availableBoardsRedux)
                    if(stateType === 'speciality' && tagText.includes('American')){
                        return (<Tag key={i} text={tagText} onClick={() => handleTagClose(selectedBoard.id)}/>)
                    }else if(stateType === 'state' && !tagText.includes('American'))
                    return (<Tag key={i} text={tagText} onClick={() => handleTagClose(selectedBoard.id)}/>)
                })}
            </Inline>
        </Columns>
    );
};

export default SpecialtyStateForm;
