import {useState} from 'react';
import {Board} from "../interfaces/board";
import {differenceBy} from "lodash";
import {useAppSelector} from "./redux";
import {selectAvailableBoards} from "../store/profile/profileSlice";

const useAvailableBoardsForm = () => {
    const availableBoardsRedux = useAppSelector(selectAvailableBoards)
    const [availableBoards, setAvailableBoards] = useState<Board[]>(availableBoardsRedux || [])

    const syncAvailableBoards = (selectedBoards: Board[]) => {
        const intersection: any = differenceBy(availableBoardsRedux, selectedBoards, 'id')
        setAvailableBoards(intersection)
    }

    return {
        availableBoards,
        syncAvailableBoards,
    }
};

export default useAvailableBoardsForm
