import React from 'react';
import { Checkbox, Inline, Text } from "@amboss/design-system";

declare type ItemDateProps = {
    date: any
    checked?: boolean
    onCheck?: (data: any) => void
}


const ItemDate = ({ date, checked, onCheck }: ItemDateProps) => {
    return (
        <Inline>
            {onCheck && <Checkbox name={'item-date'} checked={checked} onChange={e => onCheck(e.currentTarget.checked)}/>}
            <Text>{date}</Text>
        </Inline>
    );
};

export default ItemDate;
