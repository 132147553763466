import React from 'react';
import {useMediaQuery} from "react-responsive";

const InputSpacer = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
    })

    return (
        <div style={{height: isMobile ? 0 : 16}}/>
    );
};

export default InputSpacer;
