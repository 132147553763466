import React from 'react';
import {Checkbox, CheckboxProps} from "@amboss/design-system";

const CheckboxInput = ({label, name, value, checked, onChange}: CheckboxProps) => {
    return (
        <div style={{paddingTop: 8, display: 'flex'}}>
            <Checkbox
                label={label}
                name={name}
                onBlur={() => {
                }}
                onChange={onChange ? onChange : function noRefCheck() {
                }}
                onClick={function noRefCheck() {
                }}
                onFocus={function noRefCheck() {
                }}
                value={value}
                checked={checked}
            />
        </div>
    );
};

export default CheckboxInput;
