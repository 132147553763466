import { useTranslation } from "react-i18next";
import { Column, Columns, Select, Stack, Text } from "@amboss/design-system";
import DateInput from "../inputs/DateInput";
import React, {useEffect} from "react";
import TextInput from "../inputs/TextInput";
import { config } from "../../config";
import { useFormikContext } from "formik";
import { AccountSetupFields } from "../../interfaces/accountSetupFields";
import InputSpacer from "../atoms/InputSpacer";
import { useAppSelector } from "../../hooks/redux";
import { selectClinicalRoles } from "../../store/profile/profileSlice";

export default function PersonalInformationForm() {
  const { t } = useTranslation();

  const nameLabel = t("accountSetup.sections.personalInformation.form.inputs.firstName");
  const lastNameLabel = t("accountSetup.sections.personalInformation.form.inputs.lastName");
  const emailLabel = t("accountSetup.sections.personalInformation.form.inputs.email");
  const clinicalRoleLabel = t("accountSetup.sections.personalInformation.form.inputs.clinicalRole.label");
  const clinicalRoleValueLabel = t("accountSetup.sections.personalInformation.form.inputs.clinicalRole.value");
  const clinicalRoles = useAppSelector(selectClinicalRoles);

  const errorLabel = t("accountSetup.sections.personalInformation.form.errors.general");

  const options = clinicalRoles.map((c: any) => {
    return {
      value: c,
      label: t(`accountSetup.sections.personalInformation.form.inputs.clinicalRole.${c}`),
    };
  });
  
  const { values, setFieldValue, isValid, isSubmitting} = useFormikContext<AccountSetupFields>();
  const { errors, touched } = useFormikContext<any>();
  const setClinicalRoleValues =  (e: any) => {
    setFieldValue(config.forms.personalInformation.CLINICAL_ROLE, e.currentTarget.value);
    setFieldValue(config.forms.personalInformation.CLINICAL_ROLE_VALUE, e.currentTarget.textContent);
    if (e.currentTarget.value === config.forms.personalInformation.CLINICAL_ROLES.OTHER) {
      setFieldValue(config.forms.personalInformation.CLINICAL_ROLE_VALUE, "");
    }
  };

  useEffect(() => {
    if(!isValid && isSubmitting) {
      window.scrollTo({top:0})
    }
  }, [isValid, isSubmitting]);

  return (
    <>
      <Columns gap={"m"}>
        <Column size={[12, 4, 4]}>
          <TextInput type={"text"} name={config.forms.personalInformation.NAME} label={nameLabel} />
          {errors.name && touched.name && (
              <Text size="s" weight="normal" as="p" color="error">
                {errorLabel}
              </Text>
          )}
        </Column>
        <Column size={[12, 4, 4]}>
          <TextInput type={"text"} name={config.forms.personalInformation.LAST_NAME} label={lastNameLabel} />
          {errors.lastName && touched.lastName && (
              <Text size="s" weight="normal" as="p" color="error">
                {errorLabel}
              </Text>
          )}
        </Column>
        <Column size={[12, 4, 4]}>
          <DateInput
            title={t(`accountSetup.sections.personalInformation.form.inputs.birthDate`)}
            formikField={config.forms.personalInformation.BIRTH_DATE}
            defaultValue={values.birthDate}
            showDay={true}
          />
          {errors.birthDate && touched.birthDate && (
              <Text size="s" weight="normal" as="p" color="error">
                {errorLabel}
              </Text>
          )}
        </Column>
        <Column size={[12, 4, 4]}>
          <TextInput type={"text"} name={config.forms.personalInformation.EMAIL} label={emailLabel} />
          {errors.email && touched.email && (
              <Text size="s" weight="normal" as="p" color="error">
                {errorLabel}
              </Text>
          )}
        </Column>
        <Column size={[12, 4, 4]}>
          <DateInput
            title={t("accountSetup.sections.personalInformation.form.inputs.graduationDate")}
            formikField={config.forms.personalInformation.GRADUATION_DATE}
            defaultValue={values.graduationDate}
          />
          {errors.graduationDate && touched.graduationDate && (
              <Text size="s" weight="normal" as="p" color="error">
                {errorLabel}
              </Text>
          )}
        </Column>
      </Columns>
      <Stack>
        <InputSpacer />
        <Columns gap={"m"}>
          <Column size={[12, 4, 4]}>
            <Select
              name={config.forms.personalInformation.CLINICAL_ROLE}
              label={clinicalRoleLabel}
              maxHeight={200}
              onChange={setClinicalRoleValues}
              options={options}
              value={values.clinicalRole}
            />
            {errors.clinicalRoleValue && values.clinicalRole !== config.forms.personalInformation.CLINICAL_ROLES.OTHER  && (
              <Text size="s" weight="normal" as="p" color="error">
                {errorLabel}
              </Text>
            )}
          </Column>
          <Column size={[12, 4, 4]}>
            {values.clinicalRole === config.forms.personalInformation.CLINICAL_ROLES.OTHER && (
              <>
                <TextInput
                  hasHeader={false}
                  type={"text"}
                  name={config.forms.personalInformation.CLINICAL_ROLE_VALUE}
                  label={clinicalRoleValueLabel}
                />
                {errors.clinicalRoleValue && (
                  <Text size="s" weight="normal" as="p" color="error">
                    {errorLabel}
                  </Text>
                )}
              </>
            )}
          </Column>
        </Columns>
      </Stack>
    </>
  );
}
