import React from 'react';
import {H6} from "@amboss/design-system";

interface NumberInputProps {
    type: "text"
    value: string
    maxLength?:number
    min?: number
    max?: number
    label?: string,
    placeholder?: string
    name?: string,
    onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;

}

type InputProps = NumberInputProps

const NumericInput: React.FC<InputProps> = (props) => {
    const {label, placeholder} = props

    return (
        <div>
            <H6>{label}</H6>
            <div style={styles.inputContainer}>
                <input {...props} placeholder={placeholder} style={styles.input}/>
            </div>
        </div>
    )
}

const styles = {
    inputContainer: {
        marginTop: 8,
        height: 40
    },
    input: {

        padding: 8,
        width: 68,
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 400,
        // fontFamily: 'Lato',
        borderRadius: 4,

        border: '1px solid #A3B2BD',

    }
}

export default NumericInput;

