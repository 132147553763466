import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../reducers";

declare type TotalCreditsSliceProps = any;

const initialState: TotalCreditsSliceProps = {
    totalCredits: {}
}

export const totalCreditsSlice = createSlice({
    name: 'totalCredits',
    initialState,
    reducers: {
        setTotalCredits:  (state, action: PayloadAction<any>) => {
            state.totalCredits = action.payload
        },
        resetTotalCredits: (state) => {
            return {...initialState}
        }

    },
})

export const {setTotalCredits, resetTotalCredits } = totalCreditsSlice.actions

export const totalCreditsSelector = (state: RootState) => state.totalCredits.totalCredits

export default totalCreditsSlice.reducer
