import React from 'react';

export declare type CardWrapperProps = {
    children: JSX.Element
}


export default function CardWrapper({children}: CardWrapperProps) {
    return (
        <div>
            {children}
        </div>
    );
};
