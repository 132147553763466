import React, { useContext, useEffect, useState } from 'react';
import {Badge, Inline, Stack, Text} from "@amboss/design-system";
import {Trans, useTranslation} from "react-i18next";
import {ParsStatus} from "../../interfaces/parsStatus";
import {getColorFromParsStatus, getIdForTooltip, getLabelFromStatus} from "../../utils";
import Tooltip from "./Tooltip";
import {CreditsType} from "../../interfaces/credits";
import {useAppSelector} from "../../hooks/redux";
import {creditsTypeSelector} from "../../store/credits/creditsSlice";
import {config} from "../../config";

declare type ItemArticleProps = {
    searchTerm: string
    articleTitles: string[]
    parsStatus?: ParsStatus
    parsResponse?: any
}

const IpcItemArticle = ({searchTerm, articleTitles, parsStatus, parsResponse}: ItemArticleProps) => {
    const {t}  = useTranslation();
    const creditType = useAppSelector(creditsTypeSelector);

    const getTextForTooltip = () => {
        if (parsStatus === config.parsStatus.NOT_SUBMITTED) {
            return t('credits.tabs.tab3.tooltip.notSubmitted')
        }
        if (parsStatus === config.parsStatus.REJECTED) {
            return parsResponse ? `${ parsResponse.errorMessage} (Error ${parsResponse.errorCode})` : t('credits.tabs.tab3.tooltip.rejected')
        }
        return ""
    }

    const idToolTip = Math.random();

    return (
        <Stack space={'xs'}>
            <Trans i18nKey="credits.tabs.tab1.listItem.searchTerm"
                   values={{searchTerm: searchTerm}}>
                Search: <strong>{{searchTerm} as any}</strong>
            </Trans>
            <Inline>
                <Text color={'tertiary'}>
                    {articleTitles.map((title, i) => {
                        const isLast = articleTitles.length === (i - 1)
                        return (`${title}${isLast ? ',' : ''} `)
                    })}
                </Text>

                {parsStatus &&

                    <>
                        <span data-tip data-for={idToolTip.toString()}>
                        {parsStatus !== config.parsStatus.ACCEPTED &&
                            <Tooltip text={`${t('credits.tabs.tab3.tooltip.general')} ${getTextForTooltip()}`} id={idToolTip.toString()}/>
                        }
                            {creditType !== CreditsType.ABFM && <Badge color={getColorFromParsStatus(parsStatus)} text={getLabelFromStatus(parsStatus)}/> }
                        </span>
                    </>
                }
            </Inline>
        </Stack>
    );
};

export default IpcItemArticle;
