import {useSelector} from "react-redux";
import {
    resetFormData,
    selectBottomModalForm,
    selectBottomModalShow,
    setFormData,
    setShow
} from "../store/bottomModal/bottomModalSlice";
import {useAppDispatch} from "./redux";
import {BottomModalForm} from "../interfaces/bottomModal";

const useBottomModal = () => {
    const show = useSelector(selectBottomModalShow)
    const {selectedCount, creditIds} = useSelector(selectBottomModalForm)
    const dispatch = useAppDispatch()

    const showBottomModal = (show: boolean) => {
        dispatch(setShow(show))
    }

    const setBottomModalForm = (form: BottomModalForm) => {
        dispatch(setFormData(form))
    }

    const resetBottomModalForm = () => {
        dispatch(resetFormData())
    }

    return {
        creditIds,
        show,
        selectedCount,
        showBottomModal,
        setBottomModalForm,
        resetBottomModalForm,
    }
};

export default useBottomModal;
