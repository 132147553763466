import React from 'react';
import {Input, Stack} from "@amboss/design-system";
import InputSpacer from "../atoms/InputSpacer";
import {useFormikContext} from "formik";
import {useTranslation} from "react-i18next";

declare type TextInputProps = {
    hasHeader?: boolean
    name: string
    type: "text" | "password" | "number"
    label: string
    labelHint?: string
    placeholder?: string
}

const TextInput = ({
                       hasHeader = true,
                       name,
                       type,
                       placeholder = 'accountSetup.sections.personalInformation.form.placeholders.text',
                       label,
                       labelHint
                   }: TextInputProps) => {
    const {t} = useTranslation()
    const {values, setFieldValue} = useFormikContext()
    const placeholderText = t(placeholder)

    return (
        <Stack>
            {hasHeader && <InputSpacer/>}
            <Input
                label={label}
                name={name}
                placeholder={placeholderText}
                type={type}
                labelHint={labelHint}
                //@ts-ignore
                value={values[name] || ''}
                onChange={e => setFieldValue(name, e.currentTarget.value)}
            />
        </Stack>
    );
};

export default TextInput;
