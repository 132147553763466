import React from 'react';
import {SpinnerCircular} from 'spinners-react';


const Loader = () => {
    return (
        <div style={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            background: '#000',
            opacity: 0.5,
            zIndex: 9999,
        }}>
            <div style={{
                flex: 1,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <SpinnerCircular color={"#0FA980"}/>
            </div>
        </div>
    );
};

export default Loader;
