import {useContext, useEffect} from 'react';
import {SocketContext} from "../contexts/SocketProvider";
import {useSocketEmit} from "./useSocketEmit";
import {useAppDispatch, useAppSelector} from "./redux";
import {selectUser, setProfile} from "../store/profile/profileSlice";

const useProfile = () => {
    const {socketReady} = useContext(SocketContext)
    const {getProfile} = useSocketEmit()
    const profile = useAppSelector(selectUser)
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (socketReady && !profile?.id) {
            refreshProfile()
        }
    }, [socketReady])

    const refreshProfile = () => {
        getProfile().then(response => {
            dispatch(setProfile(response))
        }).catch((err) => console.warn(err?.message))
    }

    return {
        refreshProfile,
        profile
    }
};

export default useProfile;
