import React from 'react';
import {Stack} from '@amboss/design-system'

declare type SectionsProps = {
    children: JSX.Element[]
}

const Sections = ({children}: SectionsProps) => {
    return (
        <Stack space={'l'}>
            {children}
        </Stack>
    );
};

export default Sections;
