import React from 'react';
import {Icon, Text} from "@amboss/design-system";
import {useMediaQuery} from "react-responsive";

declare type TagProps = {
    text: string
    onClick: React.MouseEventHandler<HTMLDivElement>
}

const Tag = ({text, onClick}: TagProps) => {
    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
    })

    return (
        <div style={{
            alignItems: 'center',
            flexDirection: 'row',
            display: 'flex',
            backgroundColor: '#EEF2F5',
            paddingLeft: 8,
            paddingRight: 8,
            marginRight: isMobile ? 16 : 0,
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 4,
        }}>
            <Text>{text}</Text>
            <div style={{paddingLeft: 4, cursor: 'pointer'}} onClick={onClick}><Icon
                color={"tertiary"}
                name="x"
                size="s"
            />
            </div>
        </div>
    );
};

export default Tag;
