import React from 'react';
import {Text} from "@amboss/design-system";
import ReactTooltip from "react-tooltip";

declare type TooltipProps = {
    text: string
    id?:string
}

const Tooltip = ({text, id = 'global'}: TooltipProps) => {
    return (
        <ReactTooltip id={id} aria-haspopup='true' backgroundColor={'black'} className={'tooltipOpacity'}>
            <div style={{maxWidth: 1250, width:'100%', backgroundColor:'black'}} >
                <div style={{maxWidth: 265}}>
                <Text color={'accent'}><span style={{color:'white'}}>{text}</span></Text>
                </div>
            </div>
        </ReactTooltip>
    );
};

export default Tooltip;
