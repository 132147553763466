import { Badge, Box, Button, Container, H5, Icon, Inline, Stack, Text } from '@amboss/design-system';
import { useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import useProfile from "../../hooks/useProfile";
import useEditProfile from "../../hooks/useEditProfile";
import { useTranslation } from "react-i18next";
import { useModal } from "../../hooks/useModal";
import ClaimModal from "./ClaimModal";
import useBottomModal from "../../hooks/useBottomModal";
import Tooltip from "../atoms/Tooltip";
import { useSelector } from 'react-redux';
import { creditsSelector } from '../../store/credits/creditsSlice';

const BottomModal = () => {
    const isDesktopOrTablet = useMediaQuery({
        query: '(min-width: 521px)'
    })
    const { t } = useTranslation()
    const { profile } = useProfile()
    const { openProfile } = useEditProfile()
    const { selectedCount, creditIds, resetBottomModalForm } = useBottomModal()
    const { openModal, closeModal } = useModal()
    const creditsType = useSelector(creditsSelector)

    // lifecycle cleanup: form and modal should reset on component init and destroy
    useEffect(() => {
        resetBottomModalForm()
        closeModal()

        return () => {
            resetBottomModalForm()
            closeModal()
        };
    }, [])


    const handleClaimCredits = () => {   
        openModal({
            title: t('credits.tabs.tab2.claim.modal.title'),
            onClose: () => closeModal(),
            children: <ClaimModal creditIds={creditIds} />,
        })
    }

    const getButtonText = (type: string)=>{
        if(selectedCount < 0){
            return t(`credits.tabs.tab2.bottomModal.${type}.disabled`)
        }else{
            return t(`credits.tabs.tab2.bottomModal.${type}.enabled`, { count: selectedCount })
        }
    }

    return (
        <Box>
            <Container elevation={4}>
                <div style={{
                    position: "fixed",
                    bottom: 0,
                    left: '50%',
                    width: isDesktopOrTablet ? 'calc(100% - 100px)' : window.innerWidth,
                    maxWidth:"944px",
                    transform: 'translate(-50%, 0)',
                    minHeight: 94,
                    zIndex: 999,
                    backgroundColor: 'white',
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    boxShadow: '0px 4px 20px rgba(0,0,0,0.2)',
                }}>
                    <Box space={'xl'}>
                        <Stack>
                            <Inline vAlignItems={"center"} >
                                {creditsType.type === "abfm" ? 
                                <>
                                <H5>{t('credits.tabs.tab2.bottomModal.titleClaim')}</H5>
                                </>
                                :
                                <>
                                <H5>{t('credits.tabs.tab2.bottomModal.titleSubmit')}</H5>
                                <span data-tip data-for='global'><Icon name={'help-circle'} size={'s'} /></span>
                                <Tooltip text={t('credits.tabs.tab2.bottomModal.tip')} />
                                {profile?.boards && profile.boards?.length > 0 &&
                                <Inline>
                                    {profile.boards.map((board: any, i: number) => {
                                        return <Badge key={i} text={board.label} color={"gray"} />
                                    })}
                                </Inline>
                                }
                                </>
                                }
                            </Inline>
                            <Inline vAlignItems={'center'} alignItems={"spaceBetween"}>
                                <Button variant='tertiary' style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={openProfile}>{creditsType.type !== "abfm" && t('credits.tabs.tab2.bottomModal.link')}
                                </Button>
                                <Button
                                    disabled={!(selectedCount > 0)}
                                    onClick={handleClaimCredits}>
                                {creditsType.type === "abfm" ? getButtonText('buttonClaim') : getButtonText('buttonSubmit')}
                                </Button>
                            </Inline>
                        </Stack>
                    </Box>
                </div>
            </Container>
        </Box>
    );
};

export default BottomModal;
