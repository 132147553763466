import {Button, Inline, Link} from '@amboss/design-system';
import React from 'react';
import {useTranslation} from "react-i18next";
import logo from '../../assets/images/logo.svg'
import {config} from "../../config";

const Navbar = () => {
    const {t} = useTranslation()

    return (
        <div style={{
            filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12)) drop-shadow(0px 0.3px 1px rgba(0, 0, 0, 0.006))',
            backgroundColor: '#ffffff',
            width: '100%',
            height: 56,
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 999,
        }}>
            <div style={{
                paddingLeft: 24,
                marginRight: 24,
            }}>
                <Inline alignItems={'spaceBetween'} vAlignItems={'center'}>
                    <a href={config.external.links.LOGOUT}>
                      <img src={logo} alt={'logo'}/>
                    </a>
                    <Inline vAlignItems={'center'} alignItems={"spaceBetween"}>
                    <Button variant={'tertiary'}
                            onClick={() => window.location.replace(config.external.links.LOGOUT)}>
                        {t('navbar.exit')}
                    </Button>
                    <Button variant={'tertiary'}
                            onClick={()=> window.open("https://support.amboss.com/hc/en-us/categories/360004601531-AMBOSS-for-Clinicians","blank")}

                >
                         {t('navbar.help')}
                </Button>
                </Inline>
                </Inline>
            </div>
        </div>
    );
};

export default Navbar;
