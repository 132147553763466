import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import {HttpClient as HttpClientIntegration} from "@sentry/integrations";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {persistor, store} from "./store/store";
import ModalProvider from "./hooks/useModal";
import {dark, light, ThemeProvider} from "@amboss/design-system";
import SocketProvider from "./contexts/SocketProvider";
import Home from "./routes/Home";
import {Provider} from "react-redux";
import {Credits} from "./components/cards/credits/Credits";
import {PersistGate} from "redux-persist/integration/react";
import ErrorBoundary from "./components/cards/ErrorBoundary";


if (window.sentry_dsn) {
    Sentry.init({
        dsn: window.sentry_dsn,
        environment: window.environment,
        release: window.release,
        integrations: [
            new HttpClientIntegration({
                failedRequestStatusCodes: [[400, 599], 599],
            }),
        ],
        tracesSampleRate: 1.0,
        sendDefaultPii: true,
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>

    // </React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ModalProvider>
                    <ThemeProvider theme={false ? dark : light}>
                        <SocketProvider>
                            <Routes>
                                <Route path="/" element={<App/>}>
                                    <Route index element={<Home/>}/>
                                    <Route path="credits" element={<Credits/>}/>
                                    <Route path="*" element={<ErrorBoundary />} />
                                </Route>
                            </Routes>
                        </SocketProvider>
                    </ThemeProvider>
                </ModalProvider>
            </PersistGate>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
