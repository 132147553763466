import {useCallback, useEffect, useState} from 'react';
import {useSocketEmit} from "./useSocketEmit";
import {useAppDispatch} from "./redux";
import {useSelector} from "react-redux";
import {appendCredits, creditsSelector, setCredits, setCreditsPagination} from "../store/credits/creditsSlice";
import {getQueryParameterFromTab, reachedBottom} from "../utils";
import useScrollPosition from "./useScrollPosition";
import {CreditsQueryOptions} from "../interfaces/creditsQueryOptions";

const useCreditsPaginate = (collection: string) => {
    const {getCredits} = useSocketEmit()
    const dispatch = useAppDispatch()
    const credits = useSelector(creditsSelector)
    const defaultQuery = getQueryParameterFromTab(credits.type, collection);
    const [isLoading, setIsLoading] = useState(true);
    const [canScrollBottom, setCanScrollBottom] = useState(false)
    const [scrolling, setScrolling] = useState(false)
    const [historyQuery, setHistoryQuery] = useState<any>(defaultQuery)
    const scrollPosition = useScrollPosition();

    const refreshCredits = async (options?: CreditsQueryOptions, type: 'append' | 'refresh' = 'refresh') => {
        type === 'refresh' && setIsLoading(true);
        const c = await getCredits(options || defaultQuery).catch((err) => alert(err?.message))
        if (c) {
            type === 'refresh' ? dispatch(setCredits(c.credits)) : dispatch(appendCredits(c.credits))
            if(c.pagination) {
                dispatch(setCreditsPagination(c.credits))
            }
        }
        setIsLoading(false);
    }


    useEffect(() => {
        if (credits?.items.length > 0) {
            setCanScrollBottom(credits.pagination.hasMore)
        }
    }, [credits]);

    useEffect(() => {
        if (scrolling) {
            refreshCredits(historyQuery, 'append').finally(() => {
                setScrolling(false)
            });

        }
    }, [scrolling])

    const setNewHistoryQuery = useCallback(() => {
        const searchDate = credits.items[credits.items.length - 1][historyQuery.sort];
        setHistoryQuery({...historyQuery, endingBefore: searchDate});
        setScrolling(true)
    }, [credits, historyQuery]);

    useEffect(() => {
        if (reachedBottom() && canScrollBottom && !scrolling) {
            setNewHistoryQuery();
        }
    }, [scrollPosition, canScrollBottom]);


    return {
        isLoading,
        refreshCredits,
        historyQuery,
        setHistoryQuery,
        credits
    }
};

export default useCreditsPaginate;
