import { Stack } from "@amboss/design-system"
import { HomeSectionsCard } from "./HomeSectionsCard"
import {useAppDispatch} from "../../../hooks/redux";
import {useEffect} from "react";
import {setActiveTab} from "../../../store/credits/creditsSlice";


export const HomeSections = ({totals}: any) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setActiveTab(0));
    }, []);

    const totalsArray = Object.entries(totals);
    let cardContent = totalsArray?.map((type:any)=>{
            return (
            <div key={type} className="home-card-container">
                <HomeSectionsCard type={type[0]} credits={type[1]}/>
            </div>
        )
    })
    
    return(
    <Stack space={"m"}>
    <section className="home-card-section">
        {totalsArray && cardContent}
    </section>
    </Stack>
    )
}
