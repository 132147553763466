export const ReviewModel = (creditId: string) => ({
    creditId: creditId,
    review: [{
        question: 1,
        answers: [{answer: 0, value: false}, {answer: 1, value: false}, {answer: 2, value: false}, {
            answer: 3,
            value: false
        }, {
            answer: 4,
            value: false
        }]
    }, {
        question: 2,
        answers: [{answer: 0, value: false}, {answer: 1, value: false}, {answer: 2, value: false}, {
            answer: 3,
            value: false
        }]
    }]
})
