import React from 'react';
import './App.css';
import './translations/i18n'
import {Outlet} from "react-router-dom";

function App() {
    return (   
    <div style={{width: '80vw', maxWidth:"944px"}}>
        <Outlet/>
    </div>
    )
}

export default App;
