import React from 'react';
import {Column, Columns, Divider, Stack} from "@amboss/design-system";

declare type TabListProps = {
    left: React.ReactNode,
    center: React.ReactNode,
    right: React.ReactNode,
    isLastItem: boolean,
}

const TabList = ({left, center, right, isLastItem}: TabListProps) => {
    return (
        <Stack space={"m"}>
            <Columns gap={'m'} vAlignItems={"center"}>
                <Column size={[12, 4, 3]}>
                    {left}
                </Column>
                <Column size={[12, 5, 6]}>
                    {center}
                </Column>
                <Column size={[12, 3, 3]}>
                    {right}
                </Column>
            </Columns>
            {!isLastItem && <div style={{marginBottom: 16}}><Divider/></div>}
        </Stack>

    );
};

export default TabList;
