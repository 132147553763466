import {Board} from "./interfaces/board";
import {config} from "./config";
import {Qa} from "./interfaces/bottomModal";
import {ParsStatus} from "./interfaces/parsStatus";
import {Answer} from "./interfaces/answers";
import {DownloadParams} from "./interfaces/downloadParams";
import {MergeBoardsArgs} from "./interfaces/mergeBoardsArgs";
import {AccountSetupFields} from "./interfaces/accountSetupFields";
import {CreditsType} from "./interfaces/credits";

export const normalizeProfileParams = (values: AccountSetupFields) => {
    let normalizedValues: any = {...values}
    if (normalizedValues.currentBoard) {
        normalizedValues = addPendingBoard(normalizedValues);
    }
    normalizedValues.clinicalRole = {
        key: normalizedValues.clinicalRole,
        value: normalizedValues.clinicalRoleValue
    }
    delete normalizedValues.currentBoard
    delete normalizedValues.clinicalRoleValue
    return normalizedValues
}

export const mergeBoards = ({boards, currentBoard}: MergeBoardsArgs) => {
    if (currentBoard !== null) {
        return [...boards, currentBoard]
    }
    return boards
}

export const getOnChangeValue = (value: string, defaultValue:any) => {

    if (value === ""){
        value = defaultValue
    }
    return value.toString().padStart(2, "0")

}
const addPendingBoard = (normalizedValues: any) => {
    normalizedValues = {
        ...normalizedValues,
        boards: mergeBoards({ boards: normalizedValues.boards, currentBoard: normalizedValues.currentBoard })
    };
    if (normalizedValues.currentBoard) {
        delete normalizedValues.currentBoard;
    }
    return normalizedValues;
}

export function getValueFromMinMax(min: number, max: number, inputValue: string) {
    const value = parseInt(inputValue)
    if (isNaN(value)){
        return "";
    }

    const regex = /^(0?[1-9]|1[0-2])$/;
    if (regex.test(inputValue)) {
        return inputValue;
    }

    let data = value.toString()
    if (value < min) {
        data = min.toString().padStart(2, '0')
    } else if (value > max) {
        data = max.toString()
    }
    return data;
}

export function formatTagText(name: string, userCode: string | undefined) {
    if (!userCode) {
        return name
    }
    return `${name} ${userCode && '(ID:' + userCode + ')'}`
}

export function getTagFromBoard(selectedBoard: Board, availableBoards: Board[] | undefined) {
    const {id, userCode, name, label} = selectedBoard
    if (label) {
        return formatTagText(label, userCode)
    }

    if (!availableBoards) return ""
    const found = availableBoards.find(board => board.id === id)
    if (found) {
        return formatTagText(found.label, userCode)
    }
    return ""
}

export function removeItemFromArrayById(arr: any[], id: string) {
    return arr.filter(arr => arr?.id !== id)
}

export function composeQaArrayFromConfig() {
    // @ts-ignore
    return config.forms.claim.questions.map<Qa>((e: any, i: number) => ({question: i, answer: null}))
}

export function getColorFromParsStatus(parsStatus: ParsStatus) {
    switch (parsStatus as any) {
        case config.parsStatus.ACCEPTED: {
            return "green"
        }
        case config.parsStatus.REJECTED: {
            return "red"
        }
        default:
            return "yellow"
    }
}

export function getLabelFromStatus(parsStatus: ParsStatus) {
    switch (parsStatus as any) {
        case config.parsStatus.ACCEPTED: {
            return "Submitted"
        }
        case config.parsStatus.REJECTED: {
            return "Rejected"
        }
        default:
            return "Not submitted"
    }
}
export function getIdForTooltip(parsStatus: ParsStatus) {

    switch (parsStatus as any) {
        case config.parsStatus.ACCEPTED: {
            return "Submitted"+Math.random();
        }
        case config.parsStatus.REJECTED: {
            return "Rejected"+Math.random()
        }
        default:
            return "Not submitted"+Math.random()
    }
}

export function hasAtLeastOneChecked(answers: Answer[]) {
    let isValid = false
    answers.forEach(answer => {
        if (answer.value)
            isValid = true
    })
    return isValid
}

declare var process: {
    env: {
        REACT_APP_BASE_URL: string
    }
}

export function download({api, accessToken, fileName, errorString}: DownloadParams) {
    if (!accessToken) return alert(errorString)
    fetch(`${(window.be_url || process.env.REACT_APP_BASE_URL)}${api}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
            "Authorization": `Bearer ${accessToken}`,
        },
    }).then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);
        });
}
export const reachedTop = (e:any) => e.target.scrollTop === 0;

export const isScrollingUp = (e:any, offset:number) => e.target.scrollHeight - e.target.scrollTop > (offset || 1000);
export const getQueryParameterFromTab = (type: CreditsType, collection:string) => {
    switch (collection){
        case config.tabs.AVAILABLE:
            return {type: type, status: config.socket.tokens.TO_REVIEW, sort: config.socket.sort.SEARCH_DATE, limit:100}
        case config.tabs.REVIEWED:
            return {type: type, status: config.socket.tokens.REVIEWED, sort: config.socket.sort.UPDATED_AT, limit:100}
        case config.tabs.CLAIMED:
            return {type: type, status: config.socket.tokens.CLAIMED, sort: config.socket.sort.UPDATED_AT, limit:100}
    }
}
export const reachedBottom = () => {
    var st = Math.max(document.documentElement.scrollTop,document.body.scrollTop);

    if(!st){
        return false;
    }else if((Math.round(st+document.documentElement.clientHeight) + 100) >= Math.round(document.documentElement.scrollHeight) ){
        return true;
    }
    return false;
};

export const isWebview = () => {
        if (typeof window === undefined) { return false };
    
        let navigator: any = window.navigator;
    
        const standalone = navigator.standalone;
        const userAgent = navigator.userAgent.toLowerCase();
        const safari = /safari/.test(userAgent);
        const ios = /iphone|ipod|ipad/.test(userAgent);

        return ios ? !standalone && !safari : userAgent.includes('wv');
  }

