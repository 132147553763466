import {useContext} from 'react';
import {SocketContext} from "../contexts/SocketProvider";
import {config} from "../config";
import {trackEvent} from "../analytics";

export const useSocketEmit = () => {
    const {socket} = useContext(SocketContext);

    const getProfile = async () => {
        return new Promise((resolve, reject) => {
            socket.emit(config.socket.actions.GET_PROFILE, {}, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const getTotals = async () => {
      return new Promise((resolve, reject) => {
        socket.emit(config.socket.actions.GET_TOTALS, {}, response => {
            if (response.result?.success) {
                resolve(response.result.data)
            } else reject(response)
        })
    })
    }

    const saveProfile = async (options) => {
        const opt = options
        delete opt.acceptTerms
        return new Promise((resolve, reject) => {
            socket.emit(config.socket.actions.SAVE_PROFILE, opt, response => {
                if (response.result?.success) {
                    trackEvent({
                        event: config.analytics.events.CME_ACCOUNT_SETUP_SAVED,
                        options: {
                            xid: response.result.data.profile.xid,
                            clinical_role: response.result.data.profile.clinicalRole.value
                        }
                    })
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const getCredits = async (options) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.socket.actions.GET_CREDITS, options, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const reviewCredit = async (options) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.socket.actions.REVIEW_CREDIT, options, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const claimCredit = async (options) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.socket.actions.CLAIM_CREDIT, options, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    return {
        socket,
        getProfile,
        saveProfile,
        getCredits,
        reviewCredit,
        claimCredit,
        getTotals,
    }
}
