import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CreditsType} from "../../interfaces/credits";
import {RootState} from "../reducers";

declare type CreditsSliceProps = {
    credits: any
    activeTab: number
}

const initialState: CreditsSliceProps = {
    credits: {
        type: CreditsType,
        items: [],
        pagination: {
            hasMore: false
        },
    },
    activeTab: 0
}

export const creditsSlice = createSlice({
    name: 'credits',
    initialState,
    reducers: {
        setCreditType: (state, action: PayloadAction<any>) => {
            state.credits.type = action.payload
        },
        setCredits: (state, action: PayloadAction<any>) => {
            state.credits.items = action.payload.items;
        },
        setCreditsPagination: (state, action: PayloadAction<any>) => {
            state.credits.pagination = action.payload.pagination;
        },
        appendCredits: (state, action: PayloadAction<any>) => {
            state.credits.items = [...state.credits.items, ...action.payload.items]
            state.credits.pagination = action.payload.pagination;
        },
        setActiveTab: (state, action: PayloadAction<any>) => {
            state.activeTab = action.payload
        },
        resetCredits: (state) => {
            return {...initialState}
        }

    },
})

export const {
    setCreditType,
    setCredits,
    setCreditsPagination,
    appendCredits,
    setActiveTab,
    resetCredits
} = creditsSlice.actions

export const creditsSelector = (state: RootState) => state.credits.credits
export const creditsTypeSelector = (state: RootState) => state.credits.credits.type
export const tabSelector = (state: RootState) => state.credits.activeTab

export default creditsSlice.reducer
