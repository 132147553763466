import {useContext, useEffect, useState} from 'react';
import Navbar from "../components/navbar/Navbar";
import {Box, Stack} from "@amboss/design-system";
import {AccountSetup} from "../components/cards/accountSetup/AccountSetup";
import {SocketContext} from "../contexts/SocketProvider";
import useProfile from "../hooks/useProfile";
import useBottomModal from "../hooks/useBottomModal";
import {config} from "../config";
import {identify, trackEvent} from "../analytics";
import SocketErrorCard from "../components/cards/SocketErrorCard";
import {isWebview} from '../utils';
import {HomeSections} from '../components/cards/homeSections/HomeSections';
import {useSocketEmit} from '../hooks/useSocketEmit';
import {setTotalCredits, totalCreditsSelector} from "../store/totalCredits/totalCreditsSlice";
import {useAppDispatch, useAppSelector} from "../hooks/redux";

const Home = () => {
    const {profile} = useProfile()
    const {socketReady} = useContext(SocketContext)
    const {show} = useBottomModal()
    const [xid, setXid] = useState<string | null>(null)
    const {socketError} = useContext(SocketContext)
    const {getTotals} = useSocketEmit()
    const dispatch = useAppDispatch()
    const totalCredits = useAppSelector(totalCreditsSelector);

    const getTotalCredits = () => {
        getTotals()
            .then((response) => {
                dispatch(setTotalCredits(response))
            })
            .catch((e) => {
                console.log(e)
            })
    }

    useEffect(() => {
        if (socketReady) {
            getTotalCredits()
        }
    }, [socketReady])

    useEffect(() => {
        if (!xid && profile && profile.xid) {
            setXid(profile.xid)
            identify({xid: profile.xid, traits: {}})
            trackEvent({event: config.analytics.events.CME_PAGE_OPENED, options: {xid: profile.xid}})
        }

    }, [profile, xid])

    return (
        <div style={{padding:"32px 0px"}}>
            {!isWebview() && <Navbar/>}
            {socketReady &&
                <div className='home-container' style={{paddingTop: !isWebview() ? 56 : 0}}>

                    <Stack space={'m'}>
                        <AccountSetup/>

                        {totalCredits && <HomeSections totals={totalCredits}/> }
                    </Stack>
                </div>
            }
            {socketError && <SocketErrorCard/>}
        </div>
    );
};

export default Home;
