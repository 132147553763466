import {Credit} from "./credit";

export declare type Pagination = {
    hasMore: boolean
}

export declare type Credits = {
        items: Credit[]
        pagination: Pagination
}

export enum CreditsType {
    IPC = 'ipc',
    ABIM = 'abim',
    ABP = 'abp',
    ABFM = 'abfm',
}
