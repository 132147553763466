import {useState} from 'react';
import {BoardAddProps} from "../validators/types";

const useSelectBoard = () => {
    const initialValues = {id: ''} as BoardAddProps
    const [selectedBoard, setSelectedBoard] = useState<BoardAddProps>(initialValues)

    const selectBoardId = (boardId: string) => {
        setSelectedBoard(selectedBoard => ({...selectedBoard, id: boardId}))
    }

    const selectUserCode = (userCode: string) => {
        setSelectedBoard(selectedBoard => ({...selectedBoard, userCode: userCode}))
    }

    const resetBoardFields = () => {
        setSelectedBoard(initialValues)
    }

    return {
        selectBoardId,
        selectUserCode,
        selectedBoard,
        resetBoardFields
    }
};

export default useSelectBoard
