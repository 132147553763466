import React, {useEffect} from 'react';
import ClaimedList from "../lists/ClaimedList";
import EmptyPlaceholder from "../../../atoms/EmptyPlaceholder";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {creditsSelector} from "../../../../store/credits/creditsSlice";
import useCreditsPaginate from "../../../../hooks/useCreditsPaginate";
import {listen} from "../../../../events/stateManager";
import {config} from "../../../../config";
import {getQueryParameterFromTab} from "../../../../utils";
import Loader from "../../../atoms/Loader";
import {Stack} from "@amboss/design-system";

const ClaimedTab = () => {
    const {t} = useTranslation()
    const {isLoading, credits, refreshCredits} = useCreditsPaginate(config.tabs.CLAIMED)

    useEffect(() => {
        refreshCredits();
    }, [])

    return (
        <>
            {!isLoading && <Stack space={'m'}>
                {credits?.items && credits.items.length > 0 ?
                    <ClaimedList credits={credits}/> :
                    <EmptyPlaceholder header={t('credits.tabs.tab3.emptyPlaceholder.header')}
                                      subHeader={t('credits.tabs.tab3.emptyPlaceholder.subHeader')}/>
                }
            </Stack>
            }

            {isLoading && <Stack space={'m'}>
                <EmptyPlaceholder header={t('loading.header')}
                                  subHeader={t('loading.subheader')}/>
            </Stack>}
        </>
    );
};

export default ClaimedTab;
