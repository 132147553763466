import React, {SetStateAction, useEffect} from 'react';
import {Stack} from "@amboss/design-system";
import {useTranslation} from "react-i18next";
import EmptyPlaceholder from "../../../atoms/EmptyPlaceholder";
import CreditList from "../lists/CreditList";
import {useSelector} from "react-redux";
import {creditsSelector} from "../../../../store/credits/creditsSlice";
import useCreditsPaginate from "../../../../hooks/useCreditsPaginate";
import {getQueryParameterFromTab} from "../../../../utils";
import {config} from "../../../../config";
import Loader from "../../../atoms/Loader";
import {Dispatch} from "@reduxjs/toolkit";

const AvailableTab= () => {
    const {t} = useTranslation()
    const {isLoading, credits, refreshCredits} = useCreditsPaginate(config.tabs.AVAILABLE)

    useEffect(() => {
        refreshCredits();
    }, [])


    return (
        <>
            {!isLoading && <Stack space={'m'}>
                {credits?.items && credits.items.length > 0 ? <CreditList credits={credits}/> :
                    <EmptyPlaceholder header={t('credits.tabs.tab1.emptyPlaceholder.header')}
                                      subHeader={t('credits.tabs.tab1.emptyPlaceholder.subHeader')}/>}
            </Stack>}
            {isLoading && <Stack space={'m'}>
                <EmptyPlaceholder header={t('loading.header')}
                                  subHeader={t('loading.subheader')}/>
            </Stack>}
        </>
    );
};

export default AvailableTab;
