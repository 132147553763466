import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {BottomModalProps} from "../../interfaces/bottomModal";
import {RootState} from "../reducers";


const initialState: BottomModalProps = {
    show: false,
    form: {
        selectedCount: 0,
        creditIds: []
    },
}

export const bottomModalSlice = createSlice({
    name: 'bottomModal',
    initialState,
    reducers: {
        setShow: (state, action: PayloadAction<any>) => {
            state.show = action.payload
        },
        setFormData: (state, action: PayloadAction<any>) => {
            state.form = action.payload
        },
        resetFormData: (state) => {
            return {...initialState, show: state.show}
        },
    },
})

export const {setFormData, resetFormData, setShow} = bottomModalSlice.actions

export const selectBottomModalForm = (state: RootState) => state.bottomModal.form
export const selectBottomModalShow = (state: RootState) => state.bottomModal.show

export default bottomModalSlice.reducer
